import * as React from 'react'
import { useEffect, useState } from "react";
import { RichText } from 'prismic-reactjs'
import { graphql } from "gatsby";
import axios from "axios";

import Head from "../components/Head.js";
import Layout from "../components/Layout.js";
import createLink from "../components/functions/createLink.js";
import useWindowWidth from "../components/functions/useWindowWidth.js";

import {
  isEmail,
  createNewVisionArray,
  getNewsletterFields,
  handleOnChange,
  handleOnSubmit,
  handleNewsletterOnSubmit,
  debugBtn,
} from '../utils/mod.js';

import "../styles/css/vision.css"

const Vision = ({
  doc,
  articles,
  widget,
  lang,
  docs,
  cat,
  strategy,
  typology,
  region,
  country,
  footer,
  focus,
  setFocus
}) => {

  let JSONData = [];

  const getUrl = typeof window !== 'undefined' ? window.location.href : '';
  const newsletterFields = getNewsletterFields(doc);

  function cl(link) { return createLink(link, lang); }

  const [firstname, setFirstname] = useState(null);
  const [lastname, setLastname] = useState(null);
  const [job, setJob] = useState(null);
  const [description, setDescription] = useState(null);
  const [content, setContent] = useState(null);
  const [imgA, setImgA] = useState(null);
  const [icon, setIcon] = useState(null);
  const [link, setlink] = useState(null);
  const [nt, setNt] = useState(null);

  let arrayPodcastsArticles = [];
  const [arraySectionArticles, setArraySectionArticles] = useState(null);
  const [arraySectionLastarticles, setArraySectionLastarticles] = useState(null);

  const updateCV = (pos) => {
    setFirstname(RichText.asText(doc.team_grp[pos].firstname));
    setLastname(RichText.asText(doc.team_grp[pos].lastname));
    setJob(RichText.asText(doc.team_grp[pos].job));
    setDescription(RichText.asText(doc.team_grp[pos].desc));
    setContent(doc.team_grp[pos].content);
    setImgA(doc.team_grp[pos].img.url);
    setIcon(doc.team_grp[pos].icon.url);
    setlink(doc.team_grp[pos].lnklnk);
    setNt(doc.team_grp[pos].lnknt);
  };

  let windowScrollY = 0, arrayWindow = useWindowWidth();

  const animScroll = () => {
    const section = document.querySelector('.section-rock');
    let pos2 = section.offsetHeight / 3 * 1;
    let pos3 = section.offsetHeight / 3 * 2;

    const [el1, el2, el3] = section.querySelectorAll('.el');

    if (windowScrollY <= pos2) {
      el2.classList.remove('style-reach');
      el3.classList.remove('style-reach');
      if (!el1.classList.contains('style-reach')) {
        el1.classList.add('style-reach');
      };
    } else if (windowScrollY >= pos2 && windowScrollY <= pos3) {
      el1.classList.remove('style-reach');
      el3.classList.remove('style-reach');
      if (!el2.classList.contains('style-reach')) {
        el2.classList.add('style-reach');
      }
    } else if (windowScrollY >= pos3) {
      el1.classList.remove('style-reach');
      el2.classList.remove('style-reach');
      if (!el3.classList.contains('style-reach')) {
        el3.classList.add('style-reach');
      }
    }
  };

  const handleOnSubmitB = e => {
    let formValid = true;
    e.preventDefault();

    /**@type {NodeListOf<HTMLInputElement>} */
    const inputs = document.querySelectorAll('.section-contact .container-form .input');
    
    for (const input of inputs) {
      if (input.firstChild.value != null) {
        if (input.firstChild.value.length === 0) {
          formValid = false;
          input.firstChild.parentElement.classList.add('style-error');
        }
        else if (input.firstChild.getAttribute('type') === 'email') {
          if (!isEmail(input.firstChild)) {
            formValid = false;
            input.firstChild.parentElement.classList.add('style-error');
          }
          else {
            input.firstChild.parentElement.classList.remove('style-error');
          }
        }

        else {
          input.firstChild.parentElement.classList.remove('style-error');
        }
      }
    };

    if (formValid) {
      const btn = document.querySelector('.section-contact form .container-button button');

      async function callNetlify(reader) {
        await axios.post('/.netlify/functions/form-vision', {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          data: {
            firstname: document.querySelector('#cLastName').value,
            lastname: document.querySelector('#cFirstName').value,
            email: document.querySelector('#cEmail').value,
            job: document.querySelector('#cJob').value,
            message: document.querySelector('#cMessage').value,
            title: 'Candidature en provenance du site internet Matacapital',
            sendto: document.querySelector('#cSendto').innerText,
            file: reader,
          }
        });
      }

      setTimeout(() => {
        btn.classList.add('style-load');
      }, 300);

      if (document.querySelector('#cFile').files[0] === undefined) {
        callNetlify(null);
      } else {
        let reader = new FileReader();
        reader.readAsDataURL(document.querySelector('#cFile').files[0]);
        reader.onload = function () {
          callNetlify(reader.result);
        };
      }
    }
  }

  function openThisWidget(id) {
    document.querySelector('.container-podcast .container-nav .head').click();
    for (const el of document.querySelectorAll('.container-podcast .main .content-podcast .container-list .list .container-el .el')) {
      if (parseInt(el.getAttribute('data-index')) === id) {
        el.click();
      }
    };
  }

  function openWidget() {
    document.querySelector('.container-podcast .container-nav .head').click();
    document.querySelector('.container-thumb .head .action').click();
  }

  function openFocus(uid) {
    docs.forEach(function (d) {
      if (d.node.uid === uid) {
        setFocus(d);
        document.querySelector('.focus').classList.add('style-active');
        document.querySelector('.focus').scrollTop = 0;
        document.querySelector('body').classList.add('style-noscroll');
      }
    });
  }

  function openCV(i) {
    updateCV(i);
    document.querySelector('.modalCV').classList.add('style-active');
    document.querySelector('body').classList.add('style-noscroll');
    document.querySelector('.modalCV').scrollTop = 0;
  }

  function closeCV() {
    document.querySelector('.modalCV').classList.remove('style-active');
    document.querySelector('body').classList.remove('style-noscroll');
  }

  function updateSectionArticle() {
    let nbEsg = 0;
    let newArraySectionArticles = "";
    arrayPodcastsArticles.forEach((a, i) => {
      let tags = "";
      let img;
      if (a[4] === "podcast") {
        widget.body1.forEach(function (slice, j) {
          if (a[0] === j + 1) {
            img = slice.primary.img;
            slice.items.forEach(function (t, k) {
              let tag = "";
              cat.forEach(function (c, j) {
                if (c.node.slugs[0] === t.tag.slug) {
                  tag = JSON.parse(c.node.dataString).name;
                }
              });

              if (tag.length > 0) {
                if (k === slice.items.length - 1) {
                  tags += RichText.asText(tag).toUpperCase();
                } else tags += RichText.asText(tag).toUpperCase() + ",";
              }
            });
          }
        })
      } else {
        let json = JSON.parse(a[3].node.dataString);
        img = json.content_img;
        json.global_grp.forEach(function (t, i) {
          let tag = "";
          cat.forEach(function (c, j) {
            if (c.node.slugs[0] === t.tag.slug) {
              tag = JSON.parse(c.node.dataString).name;
            }
          });

          if (tag.length > 0) {
            if (i === json.global_grp.length - 1) {
              tags += RichText.asText(tag).toUpperCase();
            } else tags += RichText.asText(tag).toUpperCase() + ",";
          }
        });
      }
      let esg = false;
      tags.split(',').forEach((t, k) => {
        if (t === 'ESG') esg = true;
      })

      if (esg) {
        nbEsg++;
        if (nbEsg < 7) {
          newArraySectionArticles += '<div class="el" data-openthiswidget="' + a[0] + '">' +
            '<div class="cover">' +
            '<img src="' + img.url + '" alt="' + img.alt + '" />' +
            '<div class="container-text">';
          if (a[4] === 'article') {
            newArraySectionArticles += '<p>' + a[2] + '</p>';
          }
          if (a[4] === 'podcast') {
            newArraySectionArticles += '<p class="number">#' + a[0] + '</p>' +
              '<p class="name">' + a[3].description + '</p>' +
              '<p class="job"> Mata Capital</p>';
          }
          newArraySectionArticles += '</div>' +
            '<div class="banner">' +
            '<div class="content">';
          if (a[4] === 'article') {
            newArraySectionArticles += '<img src="/img/vision/section-articles/icn-read.svg" />' +
              '<span>' + RichText.asText(doc.articles_btna) + '</span>';
          }
          if (a[4] === 'podcast') {
            newArraySectionArticles += '<img src="/img/vision/section-articles/icn-play.svg" alt="icon play" />' +
              '<span>' + RichText.asText(doc.articles_btnp) + '</span>';
          }
          newArraySectionArticles += '</div>' +
            '</div>' +
            '</div>' +
            '<div class="text">' +
            '<div class="name">' + a[1] + '</div>' +
            '<div class="container-tag">';

          tags.split(',').forEach((t, k) => {
            newArraySectionArticles += '<div class="tag">' + t + '</div>';
          })
          newArraySectionArticles += '</div>' +
            '</div>' +
            '</div>';
        }
      }
    })

    setArraySectionArticles(newArraySectionArticles);

    document.querySelectorAll('.vision .section-articles .container-sticky .container-el .el').forEach(function (el) {
      el.addEventListener('click', function () {
        openThisWidget(parseInt(this.getAttribute('data-openthiswidget')));
      })
    })
  }

  function updateSectionLastarticles() {
    let newArraySectionLastarticles = "";

    arrayPodcastsArticles.forEach((a, i) => {
      let tags = "";
      let img;
      if (a[4] === "podcast") {
        widget.body1.forEach(function (slice, j) {
          if (a[0] === j + 1) {
            img = slice.primary.img;
            slice.items.forEach(function (t, k) {
              let tag = "";
              cat.forEach(function (c, j) {
                if (c.node.slugs[0] === t.tag.slug) {
                  tag = JSON.parse(c.node.dataString).name;
                }
              });

              if (tag.length > 0) {
                if (k === slice.items.length - 1) {
                  tags += RichText.asText(tag).toUpperCase();
                } else tags += RichText.asText(tag).toUpperCase() + ",";
              }
            });
          }
        })
      } else {
        let json = JSON.parse(a[3].node.dataString);
        img = json.content_img;
        json.global_grp.forEach(function (t, i) {
          let tag = "";
          cat.forEach(function (c, j) {
            if (c.node.slugs[0] === t.tag.slug) {
              tag = JSON.parse(c.node.dataString).name;
            }
          });

          if (tag.length > 0) {
            if (i === json.global_grp.length - 1) {
              tags += RichText.asText(tag).toUpperCase();
            } else tags += RichText.asText(tag).toUpperCase() + ",";
          }
        });
      }

      if (i < 6) {
        newArraySectionLastarticles += '<div class="el" data-openthiswidget="' + a[0] + '">' +
          '<div class="cover">' +
          '<img src="' + img.url + '" alt="' + img.alt + '" />' +
          '<div class="container-text">';
        if (a[4] === 'article') {
          newArraySectionLastarticles += '<p>' + a[2] + '</p>';
        }
        if (a[4] === 'podcast') {
          newArraySectionLastarticles += '<p class="number">#' + a[0] + '</p>' +
            '<p class="name">' + a[3].description + '</p>' +
            '<p class="job"> Mata Capital</p>';
        }
        newArraySectionLastarticles += '</div>' +
          '<div class="banner">' +
          '<div class="content">';
        if (a[4] === 'article') {
          newArraySectionLastarticles += '<img src="/img/vision/section-articles/icn-read.svg" />' +
            '<span>' + RichText.asText(doc.articles_btna) + '</span>';
        }
        if (a[4] === 'podcast') {
          newArraySectionLastarticles += '<img src="/img/vision/section-articles/icn-play.svg" alt="icon play" />' +
            '<span>' + RichText.asText(doc.articles_btnp) + '</span>';
        }
        newArraySectionLastarticles += '</div>' +
          '</div>' +
          '</div>' +
          '<div class="text">' +
          '<div class="name">' + a[1] + '</div>' +
          '<div class="container-tag">';
        tags.split(',').forEach((t, k) => {
          newArraySectionLastarticles += '<div key={k} class="tag">' + t + '</div>';
        })
        newArraySectionLastarticles += '</div>' +
          '</div>' +
          '</div>';
      }
    })

    setArraySectionLastarticles(newArraySectionLastarticles);

    document.querySelectorAll('.vision .section-lastarticles .container-sticky .container-el .el').forEach(function (el) {
      el.addEventListener('click', function () {
        openThisWidget(parseInt(this.getAttribute('data-openthiswidget')));
      })
    })
  }

  let mount = true;

  useEffect(() => {
    const newsletterContainer = document.querySelector('.section-newsletter .wrapper form .container-form');
    const newsletterBtn = newsletterContainer.querySelector('.container button');

    function anchorTeam() {
      if (getUrl.indexOf('#') > 1) {
        let hash = getUrl.split('#');
        if (hash[1] === "s7") {
          if (window.matchMedia("(min-width: 1200px)").matches) {
            window.scroll(0, document.querySelector(".section-team").offsetTop);
          } else {
            window.scroll(0, document.querySelector(".section-team").offsetTop + 55);
          }
        }
      }
    }
    anchorTeam()

    function emptyBtnESG() {
      let btn = document.querySelectorAll('.section-esg .wrapper .container .container-el .el .btn');
      btn.forEach(function (el) {
        if (el.dataset.uid === undefined) {
          el.parentElement.remove();
        }
      });

    }
    emptyBtnESG();

    function teamHover() {
      let el = document.querySelectorAll('.section-team .el .name');
      el.forEach(function (el) {
        el.addEventListener('mouseenter', () => {
          el.parentElement.classList.add('active-hover');
        })
        el.addEventListener('mouseleave', () => {
          el.parentElement.classList.remove('active-hover');
        })
      })
    }
    teamHover();

    function cardESG() {
      if (getUrl.indexOf('#') > 1) {
        let hash = getUrl.split('#');
        if (hash[1].length > 2) {
          let hashItem = hash[1];
          if (window.matchMedia("(min-width: 1200px)").matches) {
            window.scroll(0, 5680);
            setTimeout(() => {
              document.querySelector('#' + hashItem + ' .card').click();
            }, 500);
          } else {
            window.scroll(0, 4010);
            setTimeout(() => {
              document.querySelector('#' + hashItem + ' .card').click();
            }, 500);
          }

        }
      }
    }
    cardESG();

    debugBtn('.section-contact .container');

    function formSubmit() {
      let placeholder = document.querySelectorAll('.section-contact .wrapper .container .container-form form .container-input .input .placeholder');
      let field = document.querySelectorAll('.section-contact .wrapper .container .container-form form .container-input .input');
      for (let i = 0; i < field.length; i++) {
        field[i].firstChild.addEventListener('input', () => {
          if (field[i].firstChild.value !== '') {
            placeholder[i].classList.add('style-fill');
          }
          else if (field[i].firstChild.value === '') {
            placeholder[i].classList.remove('style-fill');
          }
        })

      }

    }
    formSubmit();

    function btnUpload() {
      let btnDL = document.querySelector('.section-contact .wrapper .container .container-form form .container-button .btn-download');
      let resetDL = btnDL.querySelector('.btn-reset');
      let inputDL = document.querySelector('.section-contact .wrapper .container .container-form form .container-button input');
      btnDL.firstChild.addEventListener('click', () => {
        inputDL.click();
      })
      inputDL.addEventListener('change', () => {
        let file = inputDL.files[0].name;
        if (inputDL.files[0].size > 5000000) {
          inputDL.value = '';

        } else {
          btnDL.classList.add('style-fill');
          btnDL.firstChild.innerHTML = file;
        }
      })
      resetDL.addEventListener('click', () => {
        inputDL.value = '';
        btnDL.firstChild.innerHTML = RichText.asText(doc.contact_btntxt1);
        btnDL.classList.remove('style-fill');
      })
    }

    btnUpload();

    handleNewsletterOnSubmit(newsletterContainer, newsletterBtn);

    function cardOpenFocus() {
      let btn = document.querySelectorAll('.section-esg .container .container-el .el .content .container-side .casestudy .container-btn .btn');
      btn.forEach(function (el, i) {
        el.addEventListener('click', () => {
          let uid = el.dataset.uid;
          openFocus(uid);
        })
      })

    }
    cardOpenFocus();

    /* CAROUSEL */
    function carousel() {
      let section_slider = document.querySelector('.section-slider');
      let section_sliderBtns = section_slider.querySelectorAll('.container-btn .btn');
      let section_sliderEl = section_slider.querySelectorAll('.container-el .el');
      let section_sliderImg = section_slider.querySelectorAll('.container-img img');
      let section_sliderTabs = section_slider.querySelectorAll('.container-tab .tab');
      let section_sliderCount = 1;

      let section_sliderNumEl = section_sliderEl.length;

      function prevAll(elementActive) {
        while (elementActive = elementActive.previousElementSibling) {
          elementActive.classList.add('style-prev');
        }
      };
      function nextAll(elementActive) {
        while (elementActive = elementActive.nextElementSibling) {
          elementActive.classList.add('style-next');
        }
      };

      // INIT 
      section_slider.querySelector('.el:nth-child(' + section_sliderCount + ')').classList.add('style-active');
      let elActive = section_slider.querySelector('.el.style-active');
      prevAll(elActive);
      nextAll(elActive);

      section_slider.querySelector('.container-img img:nth-child(' + section_sliderCount + ')').classList.add('style-active');
      let imgActive = section_slider.querySelector('.container-img img.style-active');
      prevAll(imgActive);
      nextAll(imgActive);

      // TAB
      section_sliderTabs.forEach(function (tab) {
        tab.classList.remove('style-active', 'style-prev', 'style-next');
      });

      section_slider.querySelector('.tab:nth-child(' + section_sliderCount + ')').classList.add('style-active');
      let tabActive = section_slider.querySelector('.tab.style-active');

      prevAll(tabActive);
      nextAll(tabActive);

      section_sliderBtns.forEach(function (btn, index) {
        btn.addEventListener('click', function () {

          if (index === 0) {
            if (section_sliderCount > 1) {
              section_sliderCount--;
            }
          } else if (index === 1) {
            if (section_sliderCount < section_sliderNumEl) {
              section_sliderCount++;
            } else if (section_sliderCount === section_sliderNumEl) {
              section_sliderCount = 1;
            }
          };

          // EL
          section_sliderEl.forEach(function (el) {
            el.classList.remove('style-active', 'style-prev', 'style-next');
          });

          section_slider.querySelector('.el:nth-child(' + section_sliderCount + ')').classList.add('style-active');
          let elActive = section_slider.querySelector('.el.style-active');
          prevAll(elActive);
          nextAll(elActive);

          // IMG 
          section_sliderImg.forEach(function (img) {
            img.classList.remove('style-active', 'style-prev', 'style-next');
          });

          section_slider.querySelector('.container-img img:nth-child(' + section_sliderCount + ')').classList.add('style-active');
          let imgActive = section_slider.querySelector('.container-img img.style-active');
          prevAll(imgActive);
          nextAll(imgActive);


          // TAB
          section_sliderTabs.forEach(function (tab) {
            tab.classList.remove('style-active', 'style-prev', 'style-next');
          });

          section_slider.querySelector('.tab:nth-child(' + section_sliderCount + ')').classList.add('style-active');
          let tabActive = section_slider.querySelector('.tab.style-active');

          prevAll(tabActive);
          nextAll(tabActive);
        });
      });


      /* AUTO PLAY */
      function autoplay() {
        var isPaused = false;

        let timeInterval = section_slider.querySelector('.play').getAttribute('data-interval');
        window.setInterval(function () {
          if (!isPaused) {

            if (section_sliderCount < section_sliderNumEl) {
              section_sliderCount++;
            } else if (section_sliderCount === section_sliderNumEl) {
              section_sliderCount = 1;
            }

            // EL
            section_sliderEl.forEach(function (el) {
              el.classList.remove('style-active', 'style-prev', 'style-next');
            });

            section_slider.querySelector('.el:nth-child(' + section_sliderCount + ')').classList.add('style-active');
            let elActive = section_slider.querySelector('.el.style-active');
            prevAll(elActive);
            nextAll(elActive);

            // IMG 
            section_sliderImg.forEach(function (img) {
              img.classList.remove('style-active', 'style-prev', 'style-next');
            });

            section_slider.querySelector('.container-img img:nth-child(' + section_sliderCount + ')').classList.add('style-active');
            let imgActive = section_slider.querySelector('.container-img img.style-active');
            prevAll(imgActive);
            nextAll(imgActive);


            // TAB
            section_sliderTabs.forEach(function (tab) {
              tab.classList.remove('style-active', 'style-prev', 'style-next');
            });

            section_slider.querySelector('.tab:nth-child(' + section_sliderCount + ')').classList.add('style-active');
            let tabActive = section_slider.querySelector('.tab.style-active');

            prevAll(tabActive);
            nextAll(tabActive);

          }
        }, timeInterval);

        let play = section_slider.querySelector('.play');
        play.addEventListener('click', function (e) {
          play.classList.toggle('style-play');
          if (play.classList.contains('style-play')) {
            e.preventDefault();
            isPaused = false;
          } else {
            e.preventDefault();
            isPaused = true;
          }

        })
        section_sliderBtns.forEach(function (btn, index) {
          btn.addEventListener('click', () => {
            isPaused = true;
            play.classList.remove('style-play');
          })
        })
      };
      autoplay();
    }
    carousel();

    function carouselTimeline() {
      let section_slider = document.querySelector('.section-timeline');
      let section_sliderBtns = section_slider.querySelectorAll('.container-btn .btn');
      let section_sliderEl = section_slider.querySelectorAll('.container-el .el');
      let section_sliderCount = 0;

      let section_sliderNumEl = section_sliderEl.length;

      // INIT 

      section_sliderBtns.forEach(function (btn, index) {
        btn.addEventListener('click', function () {

          let section_sliderStyleEl = section_sliderEl[0].currentStyle || window.getComputedStyle(section_sliderEl[0]);
          let section_sliderWidthEl = section_sliderEl[0].offsetWidth + parseInt(section_sliderStyleEl.marginRight);

          if (index === 0) {
            if (section_sliderCount >= 1) {
              section_sliderCount--;
            }
          } else if (index === 1) {
            if (section_sliderCount < section_sliderNumEl - 1) {
              section_sliderCount++;
            } else {
              section_sliderCount = 0;
            }
          };

          let slideData = -1 * (section_sliderCount * section_sliderWidthEl);
          let container = section_slider.querySelector('.container-el');
          container.style.webkitTransform = 'translateX(' + slideData + 'px' + ') translateZ(0)';
          container.style.MozTransform = 'translateX(' + slideData + 'px' + ') translateZ(0)';
          container.style.msTransform = 'translateX(' + slideData + 'px' + ') translateZ(0)';
          container.style.OTransform = 'translateX(' + slideData + 'px' + ') translateZ(0)';
          container.style.transform = 'translateX(' + slideData + 'px' + ') translateZ(0)';

        });
      });
    }
    carouselTimeline();
    /* END CAROUSEL */

    /* SECTION ESG */
    function carouselEsg() {
      let section = document.querySelector('.section-esg');
      let containerEl = section.querySelector('.container-el');
      let opens = containerEl.querySelectorAll('.el .card');
      let els = containerEl.querySelectorAll('.el');
      let closes = containerEl.querySelectorAll('.el .close')

      opens.forEach(function (open) {

        open.addEventListener('click', function () {
          let el = this.closest('.el');
          if (el.classList.contains('style-active')) {
            els.forEach(function (el) {
              el.classList.remove('style-active');
              if (window.matchMedia("(min-width: 700px)").matches) {
                el.style.webkitTransform = 'translateX(0px)';
                el.style.MozTransform = 'translateX(0px)';
                el.style.msTransform = 'translateX(0px)';
                el.style.OTransform = 'translateX(0px)';
                el.style.transform = 'translateX(0px)';
              }
            });
          } else {
            el.classList.add('style-active');

            if (window.matchMedia("(min-width: 700px)").matches) {

              let elActivePositionLeft = el.offsetLeft;

              el.style.webkitTransform = 'translateX(-' + elActivePositionLeft + 'px)';
              el.style.MozTransform = 'translateX(-' + elActivePositionLeft + 'px)';
              el.style.msTransform = 'translateX(-' + elActivePositionLeft + 'px)';
              el.style.OTransform = 'translateX(-' + elActivePositionLeft + 'px)';
              el.style.transform = 'translateX(-' + elActivePositionLeft + 'px)';

              while (el = el.nextElementSibling) {
                let elPositionLeft = el.offsetLeft;
                el.style.webkitTransform = 'translateX(' + (containerEl.clientWidth - elPositionLeft + 3) + 'px)';
                el.style.MozTransform = 'translateX(' + (containerEl.clientWidth - elPositionLeft + 3) + 'px)';
                el.style.msTransform = 'translateX(' + (containerEl.clientWidth - elPositionLeft + 3) + 'px)';
                el.style.OTransform = 'translateX(' + (containerEl.clientWidth - elPositionLeft + 3) + 'px)';
                el.style.transform = 'translateX(' + (containerEl.clientWidth - elPositionLeft + 3) + 'px)';
              }

            }
          }
        });
      });

      closes.forEach(function (close) {
        close.addEventListener('click', function () {

          els.forEach(function (el) {
            el.classList.remove('style-active');

            el.style.webkitTransform = 'translateX(0px)';
            el.style.MozTransform = 'translateX(0px)';
            el.style.msTransform = 'translateX(0px)';
            el.style.OTransform = 'translateX(0px)';
            el.style.transform = 'translateX(0px)';
          });
        });
      });
    };
    carouselEsg();
    /* END SECTION ESG */

    /* SECTION CONTACT */
    function textareaCount() {
      const textarea = document.querySelector('.section-contact textarea');
      let maxCount = textarea.maxLength;
      const count = document.querySelector('.section-contact .container-form .count span');
      textarea.onkeyup = (e) => {
        count.innerHTML = (maxCount - e.target.value.length);
      }
    };
    textareaCount();

    function textareaResize() {
      const textarea = document.querySelector('.section-contact textarea');

      textarea.addEventListener("input", function (e) {
        textarea.style.height = textarea.scrollHeight + "px";
      });
    }
    textareaResize();

    /* END SECTION CONTACT */

    /* HORIZONTAL SCROLL */
    function handleSliderHorizontalScroll(Dsection, Dcontainer, DcontainerEl, Del, Dgrid) {
      let section = document.querySelector(Dsection);
      let container = section.querySelector(Dcontainer);
      let containerEl = container.querySelector(DcontainerEl);
      let el = containerEl.querySelector(Del);
      let grid = container.querySelector(Dgrid);

      if (window.matchMedia("(min-width: 1200px)").matches) {

        /* INIT HEIGHT CONTAINER */
        container.style.height = (containerEl.clientWidth - (grid.clientWidth - el.clientHeight) + 'px');

        /* ON SCROLL */

        window.addEventListener('scroll', function () {

          let a = container.offsetTop;
          let b = arrayWindow[1] / 5; // = 10vh 
          let c = windowScrollY;
          let scrollValue = c - a + b;


          if (scrollValue > 0 && scrollValue <= (containerEl.clientWidth - grid.clientWidth)) {
            containerEl.style.transform = "translateX(-" + scrollValue + "px)";

            if (!section.classList.contains('style-scroll')) {
              section.classList.add('style-scroll');
            }

          } else if (scrollValue < 0) {
            containerEl.style.transform = "translateX(0px)";
            if (section.classList.contains('style-scroll')) {
              section.classList.remove('style-scroll');
            }
          } else if (scrollValue > (containerEl.clientWidth - grid.clientWidth)) {
            containerEl.style.transform = "translateX(calc(-100% + " + grid.clientWidth + 'px))';
          };

        })

      }
    }

    axios({
      method: "post",
      url: "https://khq9ub5smf.execute-api.eu-west-3.amazonaws.com/",
    }).then(r => {
      JSONData = r['data'];
      arrayPodcastsArticles = createNewVisionArray(JSONData, articles, RichText);

      updateSectionArticle();
      updateSectionLastarticles();

      handleSliderHorizontalScroll('.vision .section-articles', '.container', '.container-el', '.el:nth-child(1)', '.wrapper');
      handleSliderHorizontalScroll('.vision .section-lastarticles', '.container', '.container-el', '.el:nth-child(1)', '.wrapper');
    }).catch(r => { });


    function timelineColor() {
      let secTimeline = document.querySelector('.section-timeline');
      window.addEventListener('scroll', () => {
        if (window.scrollY >= 4000) {
          secTimeline.classList.add('style-scrolling');
        } else {
          secTimeline.classList.remove('style-scrolling');
        }
      })
    }
    timelineColor();

    const handleScroll = () => {
      windowScrollY = window.scrollY;
      animScroll();
    };
    animScroll();

    window.addEventListener('scroll', handleScroll);
    return () => { window.removeEventListener('scroll', handleScroll) }

    /* END HORIZONTAL SCROLL */
  }, [mount]);

  return (
    <>
      <section className="section-hero" id="s1">
        <div className="wrapper">
          <div className="container">
            <img className="obj-1" src="/img/home/section-hero/obj-1.svg" alt="" />
            <div className="container-text">
              <div className="container-path">
                <div className="path">
                  <a href="/" >{RichText.asText(doc.hero_pathtxt1)}</a>
                </div>
                <div className="sep">\</div>
                <div className="path">
                  <a href={getUrl}>{RichText.asText(doc.hero_pathtxt2)}</a>
                </div>
              </div>
              <div className="container-title">
                <h1>{RichText.asText(doc.hero_titlefirst)}</h1>
                <h1>{RichText.asText(doc.hero_titlelast)}</h1>
              </div>
            </div>
            <div className="container-img">
              <img src={doc.hero_img.url} alt={doc.hero_img.alt} />
            </div>
          </div>
        </div>
      </section>

      <section className='section-rock' id="s2">
        <div className="container-el">
          <div className="el" data-role='animContainer'>
            <div className="wrapper">
              <div className="container-title">
                <h2>{RichText.asText(doc.rock_title)}</h2>
                <div className="text">
                  <p>{RichText.asText(doc.rock_subtitle)}</p>
                  <p>{RichText.asText(doc.rock_text1)}</p>
                </div>
              </div>
              <div className="container-img">
                <div className="shape">
                  <svg height="0" width="0" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                      <clipPath id="svgPath1" clipPathUnits="objectBoundingBox">
                        <path transform="scale(0.001283697047) rotate(-0) translate(0, 0)" d="M709 709C521.039 708.792 340.835 634.03 207.918 501.114C75.0025 368.198 0.228624 187.982 0 0L347.315 0C347.44 95.8972 385.586 187.831 453.388 255.641C521.19 323.45 613.113 361.6 709 361.725L709 709Z" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <img src={doc.rock_img.url} alt={doc.rock_img.alt} />
              </div>
            </div>
            <div className="background">
              <div className="text">{RichText.asText(doc.rock_text2)}</div>
            </div>
          </div>
          <div className="el" data-role='animContainer'>
            <div className="wrapper">
              <div className="container-title">
                <h2>{RichText.asText(doc.art_title)}</h2>
                <div className="text">
                  <p>{RichText.asText(doc.art_subtitle)}</p>
                  <p>{RichText.asText(doc.art_text1)}</p>
                </div>
              </div>
              <div className="container-img">
                <div className="shape">
                  <svg width="0" height="0" viewBox="0 0 640 640">
                    <defs>
                      <clipPath id="svgPath2" clipPathUnits="objectBoundingBox" transform="scale(0.0015625, 0.0015625)">
                        <path d="M1.01717e-05 640C0.18762 470.331 67.674 307.664 187.655 187.684C307.635 67.7032 470.313 0.206367 640 0L640 313.514C553.436 313.627 470.449 348.06 409.238 409.264C348.028 470.468 313.591 553.445 313.478 640L1.01717e-05 640Z" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <img src={doc.art_img.url} alt={doc.art_img.alt} />
              </div>
            </div>
            <div className="background">
              <div className="text">{RichText.asText(doc.art_text2)}</div>
            </div>
          </div>
          <div className="el" data-role='animContainer'>
            <div className="wrapper">
              <div className="container-title">
                <h2>{RichText.asText(doc.dynamic_title)}</h2>
                <div className="text">
                  <p>{RichText.asText(doc.dynamic_subtitle)}</p>
                  <p>{RichText.asText(doc.dynamic_text1)}</p>
                </div>
              </div>
              <div className="container-img">
                <div className="shape">
                  <svg height="0" width="0" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                      <clipPath id="svgPath3" clipPathUnits="objectBoundingBox">
                        <path transform="scale(0.001214) rotate(-180) translate(-740, -740)" d="M709 709C521.039 708.792 340.835 634.03 207.918 501.114C75.0025 368.198 0.228624 187.982 0 0L347.315 0C347.44 95.8972 385.586 187.831 453.388 255.641C521.19 323.45 613.113 361.6 709 361.725L709 709Z" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <img src={doc.dynamic_img.url} alt={doc.dynamic_img.alt} />
              </div>
            </div>
            <div className="background">
              <div className="text">{RichText.asText(doc.dynamic_text2)}</div>
            </div>
          </div>
        </div>
      </section>

      <section className='section-timeline' id="s3">


        <div className="container-head">
          <div className="wrapper">
            <div className="container-text">
              <p>{RichText.asText(doc.timeline_title)}</p>
            </div>
          </div>
        </div>

        <div className="container-obj">
          <svg className="obj-1" viewBox="0 0 989 990" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
              <path d="M989 989.004C726.809 988.714 475.438 884.426 290.03 699.019C104.623 513.611 0.318913 262.224 0 0.00390625L484.478 0.00390625C484.651 133.773 537.862 262.014 632.441 356.603C727.019 451.192 855.246 504.408 989 504.582V989.004Z" />
            </g>
          </svg>
          <svg className="obj-2" viewBox="0 0 869 662" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
              <path d="M285.93 0L0 661.096H583.056L869 0H285.93Z" />
            </g>
          </svg>

        </div>

        <div className="container">
          <div className="container-sticky">
            <div className="wrapper">
              <div className="container-btn">
                <div className="btn">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                    <use xlinkHref="/img/common/icn-arrow-1.svg#content"></use>
                  </svg>
                </div>
                <div className="btn">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                    <use xlinkHref="/img/common/icn-arrow-1.svg#content"></use>
                  </svg>
                </div>
              </div>
              <div className="container-el">
                {doc.body.map((item, i) => (
                  <div key={i} className="el">
                    <div className="date">
                      {RichText.asText(item.primary.title)}
                    </div>
                    <h3>
                      {RichText.asText(item.primary.subtitle)}
                    </h3>
                    <p>
                      {RichText.asText(item.primary.text1)}
                    </p>
                    <div className="sep">
                      {RichText.asText(item.primary.text2)}
                    </div>
                    <p>
                      {RichText.asText(item.primary.text3)}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='section-slider' id="s4">
        <div className="wrapper">
          <div className="container">
            <div className="container-img">
              {doc.slider_grp.map((item, i) => (
                <img key={i} src={item.img.url} alt={item.img.alt} />
              ))}
            </div>
            <div className="container-quotes">
              <div className="container-btn">
                <div className="btn">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                    <use xlinkHref="/img/common/icn-arrow-1.svg#content"></use>
                  </svg>
                </div>
                <div className="btn">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                    <use xlinkHref="/img/common/icn-arrow-1.svg#content"></use>
                  </svg>
                </div>
              </div>
              <div className="container-el">
                {doc.slider_grp.map((item, i) => (
                  <div key={i} className="el">
                    <p>“{RichText.asText(item.quote)}”</p>
                    <div className="infos">
                      <div className="name">{RichText.asText(item.name)}</div>
                      <div className="job">{RichText.asText(item.job)}</div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="container-action">
                <div className="play style-play" data-interval="4000">
                  <img src="/img/home/section-slider/icn-play.svg" alt="" />
                  <img src="/img/home/section-slider/icn-pause.svg" alt="" />
                </div>
                <div className="container-tab">
                  <div className="tab"></div>
                  <div className="tab"></div>
                  <div className="tab"></div>
                  <div className="tab"></div>
                  <div className="tab"></div>
                  <div className="tab"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='section-esg' id="s5">
        <div className="wrapper">
          <div className="container-title">
            <h2>{RichText.asText(doc.esg_title)}</h2>
            <p>{RichText.asText(doc.esg_subtitle)}</p>
          </div>
          <div className="container">
            <div className="container-el">
              {doc.body3.map((slice, i) => {
                if (slice.slice_type === "card_cs") {
                  return (
                    <div key={i} className="el" id={"s5_" + i}>
                      <div className="card">
                        <div className="thumbnail">
                          <h3>{RichText.asText(slice.primary.title)}</h3>
                          <p>{RichText.asText(slice.primary.thumb_txt)}</p>
                        </div>
                        <div className="cross">
                          <div className="line"></div>
                          <div className="line"></div>
                        </div>
                        <img src={slice.primary.img.url} alt={slice.primary.img.alt} />
                      </div>
                      <div className="content">
                        <div className="container-text">
                          <h4>{RichText.asText(slice.primary.subtitle1)}</h4>
                          <RichText render={slice.primary.text1} />
                          <a href={cl(slice.primary.lnklnk1)} target={slice.primary.lnknt ? "_blank" : "_self"} rel="noreferrer">{RichText.asText(slice.primary.lnktxt1)}</a>
                          <h4>{RichText.asText(slice.primary.subtitle2)}</h4>
                          <RichText render={slice.primary.text2} />
                          <a href={cl(slice.primary.lnklnk2)} target={slice.primary.lnknt2 ? "_blank" : "_self"} rel="noreferrer">{RichText.asText(slice.primary.lnktxt2)}</a>
                          <a href={cl(slice.primary.lnklnk3)} target={slice.primary.lnknt3 ? "_blank" : "_self"} rel="noreferrer">{RichText.asText(slice.primary.lnktxt3)}</a>
                          <a href={cl(slice.primary.lnklnk4)} target={slice.primary.lnknt4 ? "_blank" : "_self"} rel="noreferrer">{RichText.asText(slice.primary.lnktxt4)}</a>
                        </div>
                        <div className="container-side">
                          <div className="casestudy">
                            <div className="title">{RichText.asText(slice.primary.cs_title)}</div>
                            <img src={slice.primary.cs_img.url} alt={slice.primary.cs_img.alt} />
                            <h5>{RichText.asText(slice.primary.cs_subtitle)}</h5>
                            <p>{RichText.asText(slice.primary.cs_text)}</p>
                            <div className="container-btn">
                              <div className='btn' data-uid={slice.primary.cs_btnlnk.uid}>
                                <div className="btn-text">{RichText.asText(slice.primary.cs_btntxt)}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="close">
                          <div className="line"></div>
                          <div className="line"></div>
                        </div>
                      </div>
                    </div>
                  )
                }
                else if (slice.slice_type === "card_sbt") {
                  return (
                    <div key={i} className="el" id={"s5_" + i}>
                      <div className="card">
                        <div className="thumbnail">
                          <h3>{RichText.asText(slice.primary.title)}</h3>
                          <p>{RichText.asText(slice.primary.thumb_txt)}</p>
                        </div>
                        <div className="cross">
                          <div className="line"></div>
                          <div className="line"></div>
                        </div>
                        <img src={slice.primary.img1.url} alt={slice.primary.img1.alt} />
                      </div>
                      <div className="content">
                        <div className="container-text">
                          <h4>{RichText.asText(slice.primary.subtitle1)}</h4>
                          <RichText render={slice.primary.text1} />
                          <a href={cl(slice.primary.lnklnk1)} target={slice.primary.lnknt ? "_blank" : "_self"} rel="noreferrer">{RichText.asText(slice.primary.lnktxt1)}</a>
                          <h4>{RichText.asText(slice.primary.subtitle2)}</h4>
                          <RichText render={slice.primary.text2} />
                          <a href={cl(slice.primary.lnklnk2)} target={slice.primary.lnknt2 ? "_blank" : "_self"} rel="noreferrer">{RichText.asText(slice.primary.lnktxt2)}</a>
                          <a href={cl(slice.primary.lnklnk3)} target={slice.primary.lnknt3 ? "_blank" : "_self"} rel="noreferrer">{RichText.asText(slice.primary.lnktxt3)}</a>
                          <a href={cl(slice.primary.lnklnk4)} target={slice.primary.lnknt4 ? "_blank" : "_self"} rel="noreferrer">{RichText.asText(slice.primary.lnktxt4)}</a>
                        </div>
                        <div className="container-side">
                          <div className="sbt">
                            <img src={slice.primary.img2.url} alt={slice.primary.img2.alt} />
                            <p>{RichText.asText(slice.primary.description)}</p>
                          </div>
                        </div>
                        <div className="close">
                          <div className="line"></div>
                          <div className="line"></div>
                        </div>
                      </div>
                    </div>
                  )
                } else { return (<React.Fragment key={i} />) }
              })}
              <div className="el">
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='section-articles' id="s6">
        <div className="container">
          <div className="container-sticky">
            <img className="obj-1" src="/img/vision/section-articles/obj-1.svg" alt="" />
            <div className="wrapper">
              <div className="container-title">
                <div className="title">
                  <h2>{RichText.asText(doc.articles_title)}</h2>
                  <div className="btn" onClick={() => openWidget()} onKeyDown={() => openWidget()} role="button">
                    <div className="btn-text">{RichText.asText(doc.articles_btntxt)}</div>
                  </div>
                </div>
              </div>
              <div className="container-el" dangerouslySetInnerHTML={{ __html: arraySectionArticles }} />
            </div>
          </div>
        </div>
      </section>

      <section className='section-team' id="s7">
        <div className="wrapper">
          <div className="container-title">
            <h2>{RichText.asText(doc.team_title)}</h2>
            <p>{RichText.asText(doc.team_subtitle)}</p>
          </div>
        </div>
        <div className="container">
          <div className="container-el">
            {doc.team_grp.map((item, i) => (
              <div key={i} className="el" onClick={() => openCV(i)}>
                <div className="name">
                  <span>{RichText.asText(item.firstname)}</span><strong>{RichText.asText(item.lastname)}</strong>
                </div>
                <div className="img">
                  <img src={item.img.url} alt={item.img.alt} />
                </div>
                <div className="container-text">
                  <span className="job">{RichText.asText(item.job)}</span>
                  <p className="desc">{RichText.asText(item.desc)}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <div className="modalCV">
        <div className="header">
          <div className="title">
          </div>
          <div className="close" onClick={() => closeCV()}>
            FERMER
          </div>
        </div>
        <div className="section-hero">
          <div className="container-img">
            <img src={imgA} />
          </div>
          <div className="wrapper">
            <div className="container-path">
              <div>
                <a href="/">{RichText.asText(doc.hero_pathtxt1)}</a>
              </div>
              <div className="sep">\</div>
              <div>
                <a href="">{RichText.asText(doc.hero_pathtxt2)}</a>
              </div>
              <div className="sep">\</div>
              <div>
                <a>{RichText.asText(footer.body[0].items[2].text)}</a>
              </div>
            </div>
            <div className="container">
              <div className="container-title">
                <span>{firstname}</span><strong>{lastname}</strong>
              </div>
              <div className="container-text">
                <p className="job">
                  {job}
                </p>
                <p className="desc">
                  {description}
                </p>
                <div className="content">
                  <RichText render={content} />
                </div>

              </div>
              <div className="container-link">
                <a href={link != null && cl(link)} target={nt ? "_blank" : "_self"} rel="noreferrer">
                  <img src={icon} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className='section-contact' id="s8">
        <div className="wrapper">
          <div className="container-title">
            <h2>{RichText.asText(doc.contact_title)}</h2>
          </div>
          <img className="obj-1" src="/img/vision/section-contact/obj-1.svg" alt="" />
          <div className="container">
            <div className="container-text">
              <h3>{RichText.asText(doc.contact_subtitle1)}</h3>
              <p>{RichText.asText(doc.contact_text1)}</p>
              <div className="container-btn">
                {doc.contact_grp.map((item, i) => (
                  <a key={i} href={cl(item.btnlnk)} target={item.btnnt ? "_blank" : "_self"} rel="noreferrer" className='btn'>
                    <div className="btn-text">{RichText.asText(item.btntxt)}</div>
                    <svg className="btn-icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                      <use xlinkHref="/img/common/icn-arrowext-1.svg#content"></use>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg">
                      <rect />
                    </svg>
                  </a>
                ))}
              </div>
            </div>
            <div className="container-form">
              <h3>{RichText.asText(doc.contact_subtitle2)}</h3>
              <p>{RichText.asText(doc.contact_text2)}</p>
              <div id="cSendto">{RichText.asText(doc.contact_sendto)}</div>
              <form onSubmit={handleOnSubmitB}>
                <div className="container-input">
                  <div className="input">
                    <input type="text" id="cFirstName" />
                    <span className="placeholder">
                      {RichText.asText(doc.contact_firstname)} <i>*</i>
                    </span>
                    <span className="error">
                      Champ invalide
                    </span>
                  </div>
                  <div className="input">
                    <input type="text" id="cLastName" />
                    <span className="placeholder">
                      {RichText.asText(doc.contact_lastname)} <i>*</i>
                    </span>
                    <span className="error">
                      Champ invalide
                    </span>
                  </div>
                  <div className="input">
                    <input type="email" id="cEmail" />
                    <span className="placeholder">
                      {RichText.asText(doc.contact_email)} <i>*</i>
                    </span>
                    <span className="error">
                      Champ invalide
                    </span>
                  </div>
                  <div className="input">
                    <input type="text" id="cJob" />
                    <span className="placeholder">
                      {RichText.asText(doc.contact_job)} <i>*</i>
                    </span>
                    <span className="error">
                      Champ invalide
                    </span>
                  </div>
                  <div className="input">
                    <textarea id="cMessage" maxLength="600"></textarea>
                    <span className="placeholder">
                      {RichText.asText(doc.contact_message)} <i>*</i>
                    </span>
                    <span className="error">
                      Champ invalide
                    </span>
                    <div className="count">
                      {RichText.asText(doc.contact_wordcount1)}<span>0</span>{RichText.asText(doc.contact_wordcount2)}
                    </div>
                  </div>
                  <div className="input">
                    <input name="cc-num" className="hidden" required="" value="HubspotCollectedFormsWorkaround" id="cc-num" />
                  </div>
                </div>

                <p className='cg'>
                  {RichText.asText(doc.contact_cg)}
                  <br /><a className='lgl' href={cl(doc.contact_lnklnk)} target={doc.contact_lnknt ? "_blank" : "_self"} rel="noreferrer">{RichText.asText(doc.contact_lnktxt)}</a>
                </p>
                <p className="rqd">
                  <i>*</i> {RichText.asText(doc.contact_rqd)}
                </p>
                <div className="container-button">
                  <input id="cFile" type="file" accept="application/pdf" />
                  <div className='btn-download'>
                    <div className="btn-text" style={{ display: 'none' }}>{RichText.asText(doc.contact_btntxt1)}</div>
                    <img className="btn-reset" src="/img/common/icn-quit.svg" alt="" style={{ display: 'none' }} />
                  </div>
                  <button className='btn-submit'>
                    <div className="btn-text">{RichText.asText(doc.contact_btntxt2)}</div>
                    <div className="loading">
                      <svg className="load" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                        <use xlinkHref="/img/common/icn-load.svg#content"></use>
                      </svg>
                      <svg className="check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                        <use xlinkHref="/img/common/icn-Check.svg#content"></use>
                      </svg>
                    </div>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section className='section-lastarticles' id="s9">
        <div className="container">
          <div className="container-sticky">
            <div className="wrapper">
              <div className="container-title">
                <div className="title">
                  <h2>{RichText.asText(doc.articles2_title)}</h2>
                  <div className="btn" onClick={() => openWidget()} onKeyDown={() => openWidget()} role="button" tabIndex={0}>
                    <div className="btn-text">{RichText.asText(doc.articles2_btntxt)}</div>
                  </div>
                </div>
                <p>{RichText.asText(doc.articles2_subtitle)}</p>
              </div>
              <div className="container-el" dangerouslySetInnerHTML={{ __html: arraySectionLastarticles }} />
            </div>
          </div>
        </div>
      </section>

      <section className="section-newsletter" id="s10">
        <div className="wrapper">
          <div className="container-title">
            <h2>{RichText.asText(doc.newsletter_title)}</h2>
            <p>{RichText.asText(doc.newsletter_text)}</p>
          </div>
          <form onSubmit={(e) => handleOnSubmit(e, getUrl)}>
            <div className="container-form">
              <div className="container">
                <div className="container-input">
                  {newsletterFields.map((field) => (
                    <div className="input" key={field.id}>
                      <input
                        type={field.id === "nEmail" ? "email" : "text"}
                        id={field.id}
                        onChange={handleOnChange}
                      />
                      <span className="placeholder">
                        {RichText.asText(field.content)} <i>*</i>
                      </span>
                      <span className="error">
                        Champ invalide
                      </span>
                    </div>
                    ))
                  }
                  <div className="input">
                    <input
                    name="cc-num"
                    className="hidden"
                    required=""
                    value="HubspotCollectedFormsWorkaround"
                    id="cc-num"
                    onChange={handleOnChange}
                  />
                  </div>
                </div>
                <button>
                  <span className='btn-text'>{RichText.asText(doc.newsletter_btntxt)}</span>
                  <div className="loading">
                    <svg className="load" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                      <use xlinkHref="/img/common/icn-load.svg#content"></use>
                    </svg>
                    <svg className="check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                      <use xlinkHref="/img/common/icn-Check.svg#content"></use>
                    </svg>
                  </div>
                </button>
              </div>
              <p className="rqd">
                <i>*</i> {RichText.asText(doc.newsletter_rqd)}
              </p>
              <div className="container-checkbox">
                <div className="checkbox">
                  <input type="checkbox" id="check1" onChange={handleOnChange} />
                  <div className="text">
                    <p id="check1Txt">{RichText.asText(doc.newsletter_info1)}</p>
                    <a href={cl(doc.newsletter_lnklnk)} target={doc.newsletter_lnknt ? "_blank" : "_self"} rel="noreferrer">{RichText.asText(doc.newsletter_lnktxt)}</a>
                  </div>
                </div>
                <div className="checkbox">
                  <input type="checkbox" id="check2" />
                  <div className="text">
                    <p>{RichText.asText(doc.newsletter_info2)}</p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

const InitVision = ({ data }) => {
  const lang = data.prismicVision.lang.substring(0, 2);
  const header = JSON.parse(data.prismicHeader.dataString);
  const categories = data.allPrismicCategories.edges;
  const articles = data.allPrismicArticle.edges;
  const footer = JSON.parse(data.prismicFooter.dataString);
  const doc = JSON.parse(data.prismicVision.dataString);
  const docs = data.allPrismicFocus.edges;
  const strategy = data.allPrismicStrategy.edges;
  const typology = data.allPrismicInvestmentTypology.edges;
  const region = data.allPrismicRegions.edges;
  const country = data.allPrismicCountries.edges;

  const [focus, setFocus] = useState(null);

  if (!doc || !header || !footer) return null;

  return (
    <>
      <Head doc={doc} lang={lang} />
      <div className="vision">
        <Layout
          focus={focus}
          docs={docs}
          setFocus={setFocus}
          header={header}
          articles={articles}
          cat={categories}
          footer={footer}
          lang={lang}
          type={"vision"}
        >
          <main>
            <Vision
            doc={doc}
            widget={header}
            articles={articles}
            lang={lang}
            docs={docs}
            cat={categories}
            strategy={strategy}
            typology={typology}
            region={region}
            country={country}
            footer={footer}
            focus={focus}
            setFocus={setFocus}
          />
          </main>
        </Layout>
      </div>
    </>
  );
};
export default InitVision;

export const query = graphql`
query callVision($lang: String) {
  prismicHeader(lang: { eq : $lang }) {
    dataString
  }
  allPrismicCategories(filter: {lang: {eq: $lang}}) {
    edges {
        node {
            slugs
            dataString
        }
    }
  }
  allPrismicStrategy(filter: {lang: {eq: $lang}}) {
    edges {
        node {
            slugs
            dataString
        }
    }
  }
  allPrismicInvestmentTypology(filter: {lang: {eq: $lang}}) {
    edges {
        node {
            slugs
            dataString
        }
    }
  }
  allPrismicRegions(filter: {lang: {eq: $lang}}) {
    edges {
        node {
            slugs
            dataString
        }
    }
  }
  allPrismicCountries(filter: {lang: {eq: $lang}}) {
    edges {
        node {
            slugs
            dataString
        }
    }
  }
  allPrismicFocus(filter: {lang: {eq: $lang}}) {
    edges {
        node {
            prismicId
            dataString
            first_publication_date
            uid
        }
    }
  }
  allPrismicArticle(filter: {lang: {eq: $lang}}) {
    edges {
        node {
            dataString
            first_publication_date
        }
    }
  }
  prismicVision(lang: {eq: $lang}) {
      dataString
      lang
      uid
  }
  prismicFooter(lang: {eq: $lang}) {
    dataString
  }
}
`